@import url(https://fonts.googleapis.com/css?family=Comfortaa|Patua+One);
.registration {
    border: 1px dotted lightseagreen;
    padding: 1rem;
}

.registration__game {
    font-family: "Patua One";
    font-weight: bold;
    letter-spacing: 0.15rem;
}
.events__header {
    margin: 0 0 2rem 0;
}
.titlebar {
    display: flex;
    flex-direction: row;
}

.games {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 3rem;
}

.game {
    flex-basis: 25%;
    border: 1px dotted chocolate;
    padding: 1rem;
}

.game__datetime {
    font-size: smaller;
}
.navbar {
    display: flex;
    flex-wrap: nowrap;
    justify-content: left;
}

.navbar__item {
    flex-basis: 20%;
    list-style-type: none;
}

.h1, h1 {
    font-size: 2.5rem;
}

fieldset {
    min-width: 0;
    padding: 1rem 0;
    margin: 0;
    border: 0;
}

.form--login {
    display: inline-block;
    margin: 0 auto;
    text-align: left;
}

.form--login > fieldset > input[type="email"],
.form--login > fieldset > input[type="password"] {
    width: 25em;
}

.label--login {
    width: 8rem;
    display: inline-block;
}

.form-control {
    display: block;
    width: 94%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.container--login {
    text-align: center;
}

.form--login {
    background-size: cover;
    background-position-x: center;
    background-image: url(/static/media/logo.08e55b05.png);
    z-index: 1;
    min-height: 25rem;
    min-width: 45rem;
    padding: 0 5rem;
    background-color: hsla(0,0%,100%,0.75);
    background-blend-mode: overlay;
    background-repeat: no-repeat;
}

.button--close {
    position: relative;
    bottom: -2rem;
}

.dialog {
    min-width: 15rem;
    min-height: 5rem;
}
.radius {
    border-radius: 5px;
}

.radius .tab:active::before {
    border-radius: 5px !important;
}

.border .tab {
    border: 1px solid #5fef8d;
    border-right: none;
}

.border .tab:last-child {
    border-right: 1px solid #5fef8d;
    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;
}

.border .tab:first-child {
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
}

.border .tab:active::before {
    border-radius: inherit !important;
}

.tab-bar {
    background-color: white;
    padding: 0.5rem 0 1rem 0;
    /* box-shadow: 1px 4px 20px rgba(0, 0, 0, 0.2); */
    display: flex;
    margin: 10px;
    color: black;
}

.tab-bar .tab {
    box-sizing: border-box;
    text-decoration: none;
    color: inherit;
    /* width: 70px;
    height: 70px; */
    min-height: 3rem;
    min-width: 7rem;
    background: inherit;
    padding: 5px;
    display: inherit;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: all 0.3s;
}

.tab-bar .tab::before {
    position: absolute;
    content: "";
    width: 26%;
    height: 13%;
    border-top-left-radius: 200px;
    border-top-right-radius: 200px;
    border-bottom: none;
    background-color: #607D8B;
    bottom: -8px;
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

.tab-bar .tab:active::before {
    width: 100%;
    height: 100%;
    background-color: #5fef8d;
    border-radius: 0;
}

.tab-bar .tab:hover::before {
    opacity: 1;
    bottom: 0px;
}

.tab-bar .tab::after {
    content: attr(data-text);
    position: absolute;
    top: -2rem;
    width: 100%;
    text-align: center;
    color: inherit;
    font-size: 0.75rem;
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

.tab-bar .tab:hover {
    padding-top: 1px;
}

.tab-bar .tab:hover::after {
    opacity: 1;
    top: -1rem;
}

.tab-bar .tab.selected {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: inherit;
    padding-top: 15px;
}

.tab-bar .tab.selected::after {
    opacity: 1;
    top: -2rem;
}

.tab-bar .tab.selected::before {
    opacity: 1;
    bottom: 0px;
}

.tab-bar .tab .icon {
    color: inherit;
    z-index: 10;
    font-size: 24px;
    display: inherit;
}

.tab-bar .tab .icon img {
    margin: auto;
    height: 24px;
}
/*Typography
--------------------------------------------------------------*/

body, button, input, select, textarea {
    color: #404040;
    font-family: "Comfortaa", Arial, sans-serif;
    font-size: 1rem;
    line-height: 1.5;
    padding: 0;
    margin: 0;
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Patua One", serif;
    letter-spacing: 2px;
}

h4 {
    -webkit-margin-after: 0.33rem;
            margin-block-end: 0.33rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fakeLink {
    color: -webkit-link;
    cursor: pointer;
    text-decoration: underline;
    background: none!important;
    border: none;
    cursor: pointer;
}

.fakeLink:hover {
    font-weight: bold;
}

.nav-item {
    list-style-type: none;
}

/* General button style */
.btn {
	border: none;
	font-family: Menlo;
	font-size: inherit;
	color: inherit;
	background: none;
	cursor: pointer;
    padding: 0.35rem 1.5rem;
    box-shadow: 0.25rem 0.25rem 0.7rem #90969b;
	display: inline-block;
	margin: 0.5rem 0.75rem;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 700;
	outline: none;
	position: relative;
	transition: all 0.3s;
}

.btn:after {
	content: '';
	position: absolute;
	z-index: -1;
	transition: all 0.3s;
}

/* Pseudo elements for icons */
.btn:before {
	font-style: normal;
	font-weight: normal;
	font-feature-settings: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	position: relative;
	-webkit-font-smoothing: antialiased;
}


/* Icon separator */
.btn-sep {
    padding: 0.35rem 1.5rem 0.25rem 5rem;
}

.btn-sep:before {
	background: rgba(0,0,0,0.15);
}

/* Button 1 */
.btn-1 {
	background: #3498db;
	color: #fff;
}

.btn-1:hover {
	background: #2980b9;
}

.btn-1:active {
	background: #2980b9;
	top: 2px;
}

.btn-1:before {
	position: absolute;
	height: 100%;
	left: 0;
	top: 0;
	line-height: 1.6;
	font-size: 140%;
	width: 60px;
}

/* Button 2 */
.btn-2 {
	background: #2ecc71;
	color: #fff;
}

.btn-2:hover {
	background: #27ae60;
}

.btn-2:active {
	background: #27ae60;
	top: 2px;
}

.btn-2:before {
	position: absolute;
	height: 100%;
	left: 0;
	top: 0;
	line-height: 1.7;
	font-size: 140%;
	width: 60px;
}

/* Button 3 */
.btn-3 {
	background: #e74c3c;
	color: #fff;
}

.btn-3:hover {
	background: #c0392b;
}

.btn-3:active {
	background: #c0392b;
	top: 2px;
}

.btn-3:before {
	position: absolute;
	height: 100%;
	left: 0;
	top: 0;
	line-height: 3;
	font-size: 140%;
	width: 60px;
}

/* Button 3 */
.btn-4 {
	background: #34495e;
	color: #fff;
}

.btn-4:hover {
	background: #2c3e50;
}

.btn-4:active {
	background: #2c3e50;
	top: 2px;
}

.btn-4:before {
	position: absolute;
	height: 100%;
	left: 0;
	top: 0;
	line-height: 3;
	font-size: 140%;
	width: 60px;
}

/* Icons */

.icon-create:before {
	content: "🌟";
}

.icon-send:before {
	content: "🔓";
}


